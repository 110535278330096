export default {
    "environment": "production",
    "baseURL": "https://api-5cf8df86-3e7c-441a-942a-c26fd762d73c.crave.it/",
    "googleMapURL": "https://maps.googleapis.com/maps/api/js?key=AIzaSyBp7DtXbqp9I3Bm5oUzHMB2bYoaZEtacVM&v=3.exp&libraries=geometry,drawing,places",
    "googleApiKey": "AIzaSyBp7DtXbqp9I3Bm5oUzHMB2bYoaZEtacVM",
    "client_id": "ca_FcQYjKfd1RnVJOqJYVk0q897sqQ136Sz",
    "api_key": "pk_live_AbsR8UAxGqQ12SeBawZZf19200iHWAq7yY",
    "secret_key": "sk_live_6lUEuXTPVJJUeP8v88CdZomx00sNCm1OVc",
    "logentriesToken": "58214560-f212-4093-ad98-12c2aa48ce26",
    "recaptcha_siteKey":"6Ld2rs4aAAAAAKA9jYipHeEogE083i1UwWODkxis",
    "recaptcha_secretKey":"6Ld2rs4aAAAAAGuemQRMY4yzHVwb0ePeeTPZW-uR",
    "locations": [
        {
            "hostname": [
                "jonathans",
                "jonathansv2",
                "jonathanswebapp"
            ],
            "googleApiKey": "AIzaSyBp7DtXbqp9I3Bm5oUzHMB2bYoaZEtacVM",
            "organizationId": "0a98c0a8-dcf5-4fef-abf3-d896ac540e77",
            "locationId": "e62f2d74-cd15-4b8c-8859-8879a843319b",
            "adminURL": "https://jonathans.crave.it",
            "container_width": 1400,
            "top_bar_style": {
                "backgroundColor": "rgb(255, 51, 0, 0)",
                "borderRadius": 0,
                "padding": 0
            },
            "button_color": {
                "backgroundColor": '#0db77d',
                "color": 'white',
                "selected": '#208e68',
                "menuBackground": "rgba(0,0,0,0.7)",
                "menucolor": 'white',
            },
            "label_color": {
                "color": '#0db77d'
            },
            "progress_bar_gradient": {
                "backgroundImage": "linear-gradient(to right, rgb(144, 10, 10), rgb(232,15,38))"
            },
            "backgroundImage": 'https://assets.crave.it/config_assets/organization/0a98c0a8-dcf5-4fef-abf3-d896ac540e77/jonathans_background_03_25_2021.jpeg'
        },
        {
            "hostname": [
                "devlamesa",
                "localhost:1337",
                "server-backend-env",
                "localhost:3011"
            ],
            "googleApiKey": "AIzaSyBp7DtXbqp9I3Bm5oUzHMB2bYoaZEtacVM",
            "organizationId": "7567fa60-2f79-4fe6-baf2-360f32ef0c4d",
            "locationId": "9eddb678-524b-4d12-a6a3-5b9ef773df3f",
            "adminURL": "https://devlamesa.crave.it",
            "container_width": 1400,
            "top_bar_style": {
                "backgroundColor": "rgb(255, 51, 0, 0)",
                "borderRadius": 0,
                "padding": 0
            },
            "button_color": {
                "backgroundColor": 'rgb(255, 51, 0)',
                "color": 'white',
                "selected": 'rgb(222, 44, 0)',
                "menuBackground": "rgba(0,0,0,0.7)",
                "menucolor": 'white',
            },
            "button2_color": {
                "backgroundColor": 'rgb(222, 44, 0)',
                "color": 'white',
            },
            "label_color": {
                "color": 'rgb(255, 51, 0)'
            },
            "progress_bar_gradient": {
                "backgroundImage": "linear-gradient(to right, rgb(144, 10, 10), rgb(232,15,38))"
            },
            "backgroundImage": 'background-image-2.jpg'
        },
        {
            "hostname": [
                "cassariano"
            ],
            "googleApiKey": "AIzaSyBp7DtXbqp9I3Bm5oUzHMB2bYoaZEtacVM",
            "organizationId": "90bb4fbc-5d06-4717-94cb-bb8db2957545",
            "locationId": "803e426d-c479-4afb-8b19-0c4f0ae490c1",
            "adminURL": "https://cassariano.crave.it",
            "container_width": 1400,
            "top_bar_style": {
                "backgroundColor": "rgb(255, 51, 0, 0)",
                "borderRadius": 0,
                "padding": 0
            },
            "button_color": {
                "backgroundColor": 'rgb(255, 51, 0)',
                "color": 'white',
                "selected": 'rgb(222, 44, 0)',
                "menuBackground": "rgba(0,0,0,0.7)",
            },
            "button2_color": {
                "backgroundColor": 'rgb(222, 44, 0)',
                "color": 'white',
            },
            "label_color": {
                "color": 'rgb(255, 51, 0)'
            },
            "progress_bar_gradient": {
                "backgroundImage": "linear-gradient(to right, rgb(255, 51, 0), rgb(255,136,38))"
            },
            "backgroundImage": 'https://assets.crave.it/config_assets/organization/90bb4fbc-5d06-4717-94cb-bb8db2957545/Cassariano_background.jpg'
        },
    ]
};
